

$('document').ready(function () {

	$('.js-why-karen').responsiveEqualHeightGrid();

	$('.js-treatment-card-title').responsiveEqualHeightGrid();

	$(".js-header").headroom({
		offset : 150, 
		tolerance : {
        	up : 5,
        	down : 0
    	}
    });

	$('.js-burger').click(function(){
		$('.js-mobile-menu').toggleClass('ty-100p');
		$('.js-menu-open').toggleClass('dn');
		$('.js-menu-close').toggleClass('dn');

		$('.js-header').toggleClass('menu-open');

	});

	$('.js-scroll-to').click(function(e){
		var id = $(this).attr("href")
		e.preventDefault();
		$('html, body').animate({
		    scrollTop: $(id).offset().top
		}, 500);
	});

	/*$('.js-burger').click(function(){
		$('.js-menu').toggleClass('is-open');
		$('.js-header').toggleClass('headroom--menu-open');

		var txt = $(".js-menu").hasClass('is-open') ? 'Close' : 'Explore';

		$('.js-burger .i').fadeOut(200, function(){
			$(this).toggleClass('i--menu');
			$(this).toggleClass('i--menu-close').fadeIn(200);
		});

		$('.js-burger .icon__text').fadeOut(200,function(){
			$(this).text(txt).fadeIn(200);
		});
	});*/

});


